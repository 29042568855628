  
$font-path: '../fonts';

@mixin font-face($family, $filename, $weight, $style) {

   $weight: '400' !default;
   $style: 'normal' !default;

   @font-face {
      font-family: "#{$family}";
      src: url("#{$font-path}/#{$filename}.eot");
      src: local("#{$family}"),
           url("#{$font-path}/#{$filename}.eot?#iefix") format("embedded-opentype"),
           url("#{$font-path}/#{$filename}.woff2") format("woff2"),
           url("#{$font-path}/#{$filename}.woff") format("woff"),
           url("#{$font-path}/#{$filename}.ttf") format("truetype"),
           url("#{$font-path}/#{$filename}.svg##{$filename}") format("svg");
      font-weight: $weight;
      font-style: $style;
      font-display: swap;
   }

}

@include font-face('apercu-pro', 'ApercuPro-Italic', 400, italic);
@include font-face('apercu-pro', 'ApercuPro-BoldItalic', 700, italic);
@include font-face('apercu-pro', 'ApercuPro-Medium', 500, normal);
@include font-face('apercu-pro', 'ApercuPro-Bold', 700, normal);
@include font-face('apercu-pro', 'ApercuPro-MediumItalic', 500, italic);
@include font-face('apercu-pro', 'ApercuPro', 400, normal);