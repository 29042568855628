label {
   @apply
      font-bold
      block
      mb-2;
}
input,
textarea,
select {
   @apply
      block
      w-full
      py-3
      px-4
      border-2
      border-primary;
      &:focus {
         @apply
            border-black;            
      }
}

input#secretHoneypotParamName { display: none; }

.ptable {
   @apply
      w-full
      grid
      grid-cols-3;
   @media (min-width: 700px) {
      @apply
         grid-cols-4;
   }
   @media (min-width: 800px) {
      @apply
         grid-cols-5;
   }
   @media (min-width: 920px) {
      @apply
         grid-cols-6;
   }
   @media (min-width: 1100px) {
      @apply
         grid-cols-8;
   }
   @media (min-width: 1200px) {
      @apply
         w-full
         grid
         grid-cols-10;
   }
   &__item {      
      @apply
         text-center
         border-2
         border-white
         p-2; 
      &:not(.active) {
         &:hover {
            transform: scale(1.1);     
            @media (min-width: 700px) {
               transform: scale(1.1);  
            }
            @media (min-width: 800px) {
               transform: scale(1.2);  
            }
            @media (min-width: 920px) {
               transform: scale(1.3);  
            }
            @media (min-width: 1100px) {
               transform: scale(1.4); 
            }
         }
      }
      &.active {
         grid-area: span 2 / span 5;
      }
   }
   &__subtext,
   &__header {
      @apply 
         mb-2;
   }
   &__header {
      font-size: 2.5vw;  
      @apply
         flex
         mb-2
         justify-between
         w-full;   
      @media (min-width: 700px) {
         font-size: 2.5vw;  
      }
      @media (min-width: 800px) {
         font-size: 2vw;  
      }
      @media (min-width: 920px) {
         font-size: 1.6vw;  
      }
      @media (min-width: 1100px) {
         font-size: 1.25vw;  
      }
      @media (min-width: 1200px) {
         font-size: 0.9vw;  
      }   
   }
   &__name {
      font-size: 3.5vw; 
      @apply
         block
         font-bold; 
      @media (min-width: 700px) {
         font-size: 2.8vw;  
      }
      @media (min-width: 800px) {
         font-size: 2.5vw;  
      }
      @media (min-width: 920px) {
         font-size: 2.25vw;  
      }
      @media (min-width: 1100px) {
         font-size: 1.6vw;  
      }
      @media (min-width: 1200px) {
         font-size: 1.2vw;  
      }                
   }
   &__subtext,
   &__footer {
      font-size: 3vw;     
      @apply
         block
         mb-2;
      @media (min-width: 700px) {
         font-size: 2.5vw;  
      }
      @media (min-width: 800px) {
         font-size: 2vw;  
      }
      @media (min-width: 920px) {
         font-size: 1.6vw;  
      }
      @media (min-width: 1100px) {
         font-size: 1.25vw;  
      }
      @media (min-width: 1200px) {
         font-size: 0.75vw;          
      }
   }  
}

.ptable__info {
   @apply
      fixed;
   width: 400px;   
   &.is-active {
      z-index: 15;
   }
}

.ptable__content {
   .button.border {
      border-width: 2px;
   }
}