.burger {
   @apply 
      transition-all
      h-4
      w-5;
   span {
      position: absolute !important;
      height: 2px;
      @apply 
         transition-all
         block
         w-full
         bg-black;
      &:first-child {
         top: 0;
      }
      &:last-child {
         bottom: 0%;
      }
      &:nth-child(2) {
         top: 50%;
         transform: translateY(-50%);
      }   
   }         
}

.js-menu.open {
      @apply
         text-white
         bg-black;
   .burger span {   
      @apply
         bg-white;   
      top: 50%;
      transform: translateY(-50%);
      &:first-child {
         transform: translateY(-50%) rotate(-45deg);
      }
      &:last-child {
         transform: translateY(-50%) rotate(45deg);
      }
      &:nth-child(2) {
         opacity: 0;
      }
   }
}

.offcanvas-menu {
   @apply
      -right-full; 
   &.open {
      @apply
         right-0;
   }     
}