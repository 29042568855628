.carousel {      
   &__item {
      position: relative;
      &:before {
         content: '';
         @apply 
            top-0
            left-0
            w-1
            h-full
            bg-primary         
            absolute;         
      }
   }
   &--service-light {
      .carousel__item:before {
         @apply 
            bg-white;
      }
   }
}