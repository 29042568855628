// Font Face
// @include font-face('family', 'filename', 500, 'normal');

// Body

html {
   @apply text-base overflow-x-hidden;
}

body {
   @apply
      font-sans-primary
      text-black
      text-optimize-legibility
      antialiased;
}

// Links

a {
   @apply transition;
}

// Lists
ul li {
   @apply mb-2;
   &:last-of-type {
      @apply mb-0;
   }
   &:empty {
      @apply hidden;
   }
}

// Typography
p {
   @apply mb-4;
   &:last-of-type {
      @apply mb-0;
   }
   &:empty {
      @apply hidden;
   }
   a {
    @apply
        text-black
        underline;
   }
}

// Height
.h-hero {
   height: calc(100vh - 10rem);
   @media (max-height: 625px) {
      height: calc(100vh - 2rem);
   }
}

// Selection

::selection {
   @apply
      text-gray-500
      bg-primary;
}

// FOUT fix

.wf-loading {
   @apply invisible;
}

.wf-active,
.wf-inactive {
   @apply visible;
}