.button {
   @apply
      inline-flex
      py-2
      px-3
      border-2
      border-black
      items-center
      relative
      transition;

   @media (min-width: 1024px) {
      @apply
         py-3
         px-4;
   }      

   span {
      @apply 
         z-20
         relative;
   }
   
   img {
      @apply
         w-4
         h-4
         relative
         z-20
         ml-2;
   }

   &--primary,
   &--secondary {
      @apply
         relative
         transition-all;
      &::after,
      &::before {
         content: '';
         @apply         
            h-full
            absolute
            bg-black        
            z-10            
            transition-all;           
      }          
   }

   &--primary {
      @apply          
         text-white;      
      &::before {
         @apply 
            w-full  
            duration-300
            right-0;    
      }     
      &::after {
         @apply     
            w-0
            left-0
            duration-500
            delay-100;        
      }  
      &:hover {           
         &::before {
            @apply
               w-0;
         }    
         &::after {               
            @apply
               w-full;                 
         }      
      }        
   }
   &--secondary {
      @apply          
      overflow-hidden
         text-black;      
      &::before {
         @apply 
            w-full            
            transform
            translate-x-full
            duration-300
            right-0;    
      } 
      &::after {
         @apply     
            w-full            
            transform
            translate-x-full
            duration-500
            delay-100
            right-0;         
      }            
      &:hover {          
         &::before {
            @apply   
               left-0            
               w-0;
         } 
         &::after {
            @apply   
               -translate-x-full
               left-0;
         }                 
      }        
   }
   // &--secondary {
   //    &::before {
   //       content: '';
   //       @apply 
   //          w-0 
   //          duration-300
   //          right-0;    
   //    }     
   //    // &::after {
   //    //    content: '';
   //    //    @apply      
   //    //       w-0
   //    //       right-0
   //    //       duration-500
   //    //       delay-100;        
   //    // } 
   //    &:hover {  
   //       &::before {
   //          @apply
   //             w-full;
   //       }    
   //       // &::after {               
   //       //    @apply
   //       //       w-full;                 
   //       // }      
   //    }
   // }   
}