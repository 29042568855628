// ## Page styles
// -------------------
.body-blackout {
    position: fixed;
    z-index: 1010;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .65);
    display: none;
    
    &.is-blacked-out {
      display: block;
    }
  }
  
  .popup-trigger {
    display: inline-block;
  }
  
  .popup-modal {
    width: 800px;
    max-width: 80%;
    max-height: 80%;
    background-color: #fff;
    position: fixed;
    overflow: scroll;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
    opacity: 0;
    pointer-events: none;
    transition: all 300ms ease-in-out;
    z-index: 1011;
    
    &.is--visible {
      opacity: 1;
      pointer-events: auto;
    }
    
    &__close {
      position: absolute;
      width: 40px;
      height: 40px;
      right: 0px;
      top: 0px;
      cursor: pointer;
      img {
          width: 100%;
          height: 100%;
      }
    }
  }