@tailwind base;
@tailwind components;

@import './base';
@import "utilities/font-face.scss";
@import "utilities/rem.scss";
@import "components/buttons.scss";
@import "components/carousel.scss";
@import "components/form.scss";
@import "components/map.scss";
@import "components/menu.scss";
@import './plugins/flickity.scss';
@import './plugins/modal.scss';

@tailwind utilities;
